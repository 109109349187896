import React, { Component } from 'react'
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

export default class ImagenConDiv extends Component {

    style = {
        container: {
            position: "relative",
            textAlign: "center"
        },
        imagen: {
            height: "1200px",
            width: "100%",
            objectFit: "cover",
            objectPosition: "0% 0",
            margin: "auto"
        },
        children: {
            position: "absolute",
            left: "0%",
            top: "0%",
            width: "100%",
            height: "100%"
        }
    }

    render() {
        return (
            <div style={{ marginTop: "30px" }} >
                <div className={this.props.clase} style={this.style.container} >

                    <img style={this.style.imagen} className="lazyload" data-src={this.props.src} alt={this.props.alt} />

                    <div style={this.style.children}  >
                        {this.props.children}
                    </div>
                </div>
            </div>
        )
    }
}
