import React from 'react';
import './SeccionInicio.css';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import imagen1_1 from "./media/automatizacion-1280.jpg";
import imagen1_2 from "./media/automatizacion-800.jpg";
import imagen1_3 from "./media/automatizacion-640.jpg";
import imagen1_4 from "./media/automatizacion-500.jpg";
import imagen2_1 from "./media/ingenieria-1280.jpg";
import imagen2_2 from "./media/ingenieria-800.jpg";
import imagen2_3 from "./media/ingenieria-640.jpg";
import imagen2_4 from "./media/ingenieria-500.jpg";
import imagen3_1 from "./media/mantenimiento-industrial-1280.jpg";
import imagen3_2 from "./media/mantenimiento-industrial-800.jpg";
import imagen3_3 from "./media/mantenimiento-industrial-640.jpg";
import imagen3_4 from "./media/mantenimiento-industrial-500.jpg";
import { ArrowLeftIcon, ArrowRightIcon } from '@primer/octicons-react';

function Carrusel(props) {
    const estilo = {
        contenedor: {
            alignContent: "center",
            alignItems: "center",
        },
    };

    const sizes = ` (max-width: 500px) 450px, (max-width: 640px) 600px, (max-width: 800px) 800px, 1280px"`
    
    return (
        <div id="inicio" style={estilo.contenedor} >
            <Carousel centered keepDirectionWhenDragging autoPlay={4000} infinite arrowLeft={<div className="flecha-izquierda"> <ArrowLeftIcon size={24} /> </div>} arrowLeftDisabled={<ArrowLeftIcon />} arrowRight={<div className="flecha-derecha" > <ArrowRightIcon size={24} /> </div>} arrowRightDisabled={<div></div>} addArrowClickHandler stopAutoPlayOnHover={false} style={{ width: "100%" }}>
                {
                    props.carrusel.map((item, i) => (
                        <div className="seccion-carrusel" key={i}>
                            <img data-src={item.imagen} data-sizes={sizes} data-srcset={item.srcset} className="imagen-carrusel lazyload" alt="imagen de inicio de Bentz Ingeniería" />
                            <div className="contenedor-carrusel">
                                <p>{item.titulo}</p>
                                <h1>{item.subtitulo}</h1>
                                <h4 className="texto-carrusel" >{item.texto}</h4>
                                {/* <p className="parrafo-carrusel">{item.parrafo}</p> */}
                            </div>
                        </div>
                    ))
                }
            </Carousel>
        </div>
    );
}

export default function SeccionInicio() {
    const carrusel = [
        {
            imagen: imagen1_1,
            srcset: `${imagen1_1} 1280w, ${imagen1_2} 800w, ${imagen1_3} 640w, ${imagen1_4} 500w`,
            titulo: "CREACIÓN Y EJECUCIÓN",
            subtitulo: "PROYECTOS DE AUTOMATIZACIÓN",
            texto: "INTERFAZ - PROGRAMACIÓN - CÁLCULOS",
            parrafo: "Programación de equipos de control industrial, diseño interfaz hombre-máquina, planos eléctricos, diagrama P&ID, filosofias de control, estudio de impacto y consecuencias del proyecto, sintonicazión u optimizacion de lazos PID y asesorías técnicas ",
        },
        {
            imagen: imagen2_1,
            srcset: `${imagen2_1} 1280w, ${imagen2_2} 800w, ${imagen2_3} 640w, ${imagen2_4} 500w`,
            titulo: "SERVICIO DE CALIDAD Y EFICIENCIA",
            subtitulo: "MANTENIMIENTO ELÉCTRICO INDUSTRIAL",
            texto: "MEDICIONES - CÁLCULOS - PLANES DE ACCIÓN",
            parrafo: "",
        },
        {
            imagen: imagen3_1,
            srcset: `${imagen3_1} 1280w, ${imagen3_2} 800w, ${imagen3_3} 640w, ${imagen3_4} 500w`,
            titulo: "DISEÑO Y DESARROLLO",
            subtitulo: "PROYECTOS ELÉCTRICOS",
            texto: "ESTUDIO - INGENIERÍA - PUESTA EN MARCHA",
            parrafo: "",
        },
    ]

    return (
        <div style={{ marginTop: "90px" }}>
            <Carrusel carrusel={carrusel} />
        </div>
    )
}
