import foto from './media/separador.svg'
import React, { Component } from 'react'
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import BeforeOfTitle from './BeforeOfTitle';


export default class Separador extends Component {

    style = {
        imagen: { 
            width: "150px",
            height: "170px",
            display: this.props.display
        }
    }
    render() {
        return (
            <div style={{ marginTop: "30px", textAlign: "center" }}>
                <img data-src={foto} style={this.style.imagen} className="lazyload" alt="separador" />
                <BeforeOfTitle text={this.props.subtitulo} />
                {/* <h4 style={{ margin: "20px 0" }} > {} </h4> */}
                <h2> {this.props.titulo} </h2>
                <br />
                <br />
            </div>
        )
    }
}

