import './SeccionComoTrabajamos.css'
import React, { useState } from 'react'
import { Container, Row, Col } from 'reactstrap'
import fondo from './media/seccion_como_trabajamos.jpg'
import { PersonIcon, ClippyIcon, DeviceDesktopIcon, ThumbsupIcon } from '@primer/octicons-react';
import { MDBAnimation } from 'mdbreact';
import ImagenConDiv from './ImagenConDiv';
import './imagenes.css';
import Separador from './Separador';

const Proceso = (props) => {
    const onClick = () => {
        props.cambiarTexto(props.props);
    }
    return (
        <MDBAnimation reveal type="bounceInRight">
            <Col onClick={() => onClick()} className="proceso" >
                <div className="icono-proceso" >
                    <div className="icono-interior" >
                        {props.props.icono}
                    </div>
                </div>
                <h6 className="proceso-texto">
                    {props.props.titulo}
                </h6>
            </Col>
        </MDBAnimation>
    )
}

export default function SeccionComoTrabajamos() {
    const size = 40;
    const comoTrabajamos = [
        {
            titulo: "CONTACTO",
            texto: "Vía telefónica o por correo, definimos preliminarmente el problema a resolver y/o proyecto a ejecutar y agendamos una visita técnica.",
            icono: <PersonIcon size={size} />
        },
        {
            titulo: "VISITA TÉCNICA",
            texto: "Visita en terreno, dónde se recaba información y detalles sobre tiempos, materiales a utilizar y necesidades especiales.",
            icono: <ClippyIcon size={size} />
        },
        {
            titulo: "COTIZACIÓN Y PLANIFICACIÓN",
            texto: "Se entrega una cotización al cliente, con la especificación de los trabajos a realizar, tiempos estimados y valores.",
            icono: <DeviceDesktopIcon size={size} />
        },
        {
            titulo: "EJECUCIÓN Y ENTREGA",
            texto: "Una vez confirmada la cotización y luego de emitida la orden de compra, inicia la ejecución de acuerdo a lo solicitado, ademas, una vez ejecutado el proyecto, se entrega oficialmente al cliente el cual debe dar conformidad de este.",
            icono: <ThumbsupIcon size={size} />
        }
    ]
    const [titulo, setTitulo] = useState(comoTrabajamos[0].titulo)
    const [descripcion, setDescripcion] = useState(comoTrabajamos[0].texto)
    const cambiarTexto = (texto) => {
        if (texto.titulo !== titulo) {
            setTitulo(texto.titulo);
            setDescripcion(texto.texto);
        }
    }
    return (
        <div id="como_trabajamos">
            <ImagenConDiv src={fondo} alt={"fondo"} clase="oscurecer" >
                <div className="container-como-trabajamos">
                    <Separador subtitulo="¿Cómo trabajamos?" titulo="El proceso" opacity="0.1" />
                    <MDBAnimation reveal >
                        <Container >
                            <Row xs="2" sm="4">
                                {
                                    comoTrabajamos.map((item, i) => {
                                        return <Proceso key={i} props={item} cambiarTexto={cambiarTexto} />
                                    })
                                }
                            </Row>
                            <MDBAnimation reveal type="fadeInUp">
                                <h5 className="titulo-como-trabajamos">
                                    {titulo}
                                </h5>
                                <p className="texto-como-trabajamos" >
                                    {descripcion}
                                </p>
                            </MDBAnimation>
                        </Container>
                    </MDBAnimation>
                </div>
            </ImagenConDiv>
        </div>
    )
}
