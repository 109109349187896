import './SeccionServicios.css'
import React from 'react'
import { Row, Col, Container } from 'reactstrap'
import { ClippyIcon, ZapIcon, GearIcon, GraphIcon, CheckCircleIcon, LightBulbIcon } from '@primer/octicons-react'
import { MDBAnimation } from 'mdbreact'
import Separador from './Separador'

const Servicios = (props) => {
    return (
        <MDBAnimation reveal type="fadeInUpBig">
            <Col >
                <div className="container-servicios" >
                    <div>{props.props.icono}</div>
                    <h4>{props.props.titulo}</h4>
                    <p>{props.props.texto}</p>
                </div>
            </Col>
            <br />
        </MDBAnimation>
    )
}

export default function SeccionServicios() {
    const size_icon = 60;
    const servicio = [
        {
            titulo: "SERVICIOS DE INGENIERÍA",
            texto: "Evaluamos y llevamos a cabo un estudio que permita desarrollar los lineamientos generales de tu proyecto, mejorando los procesos de producción y costos asociados. Este servicio se subdivide en ingeniería conceptual, ingeniería básica e ingeniería de detalle.",
            icono: <GraphIcon size={size_icon} />
        },
        {
            titulo: "INSCRIPCIÓN SEC",
            texto: "Regularización de proyectos eléctricos ante la SEC con personal altamente calificado autorizados por la Superintendencia de Electricidad y Combustible.",
            icono: <CheckCircleIcon size={size_icon} />
        },
        {
            titulo: "INGENIERÍA ELÉCTRICA",
            texto: "Generación de proyectos eléctricos domiciliarios e industriales, instalaciones eléctricas, desarrollo de proyectos de eficiencia energética, control de motores  (VdF y partidores suaves), y mediciones eléctricas.",
            icono: <LightBulbIcon size={size_icon} />
        },
        {
            titulo: "MANTENIMIENTO ELÉCTRICO INDUSTRIAL",
            texto: "Realización de mantenimientos correctivos y mantenimiento preventivo en diversas áreas de la electricidad e instrumentación industrial, disminuyendo los tiempos muertos al existir un fallo eléctrico grave y posibles consecuencias a las personas y equipos, generando un diagnostico antes de tiempo para su reparación.",
            icono: <ClippyIcon size={size_icon} />
        },
        {
            titulo: "AUTOMATIZACIÓN INDUSTRIAL",
            texto: "Programación de controladores Siemens, sintonización u optimización de lazos PID, control ON/OFF, desarrollo de sistemas SCADA,  diseño de diagramas P&ID y diagramas de lazos de control, emplazamiento de tableros y ejecución de proyectos",
            icono: <GearIcon size={size_icon} />
        },
        {
            titulo: "TABLEROS ELÉCTRICOS",
            texto: "Diseño y fabricación de tableros eléctricos de control y/o fuerza.",
            icono: <ZapIcon size={size_icon} />
        },
    ]
    return (
        <div id="servicios">
            <Container>
                <Separador subtitulo="Nuestros servicios" titulo="Bentz Ingeniería" opacity="0.3" />
                <Row xs="1" sm="1" md="3" >
                    {
                        servicio.map((item, i) => {
                            return <Servicios key={i} props={item} />
                        })
                    }
                </Row>
                <br />
                <br />
            </Container>
        </div>
    )
}
