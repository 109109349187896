import './SeccionMarcas.css'
import React from 'react'

import marca1 from './media/marcas/3M 100px-min.png'
import marca2 from './media/marcas/ABB 100px-min.png'
import marca3 from './media/marcas/Danfoss 100px-min.png'
import marca4 from './media/marcas/Eaton 100px-min.png'
import marca5 from './media/marcas/General Electric 100px-min.png'
import marca6 from './media/marcas/Legrand 100px-min.png'
import marca7 from './media/marcas/Megabright 100px-min.png'
import marca8 from './media/marcas/Omron 100px-min.png'
import marca9 from './media/marcas/Phoenix Contact 100px-min.png'
import marca10 from './media/marcas/Rittal 100px-min.png'
import marca11 from './media/marcas/Rockwell Automation 100px-min.png'
import marca12 from './media/marcas/Schneider Electric 100px-min.png'
import marca13 from './media/marcas/Siemens 100px-min.png'

import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import { Container } from 'reactstrap';

const Marca = (props) => {
    return (
        <div className="marca" >
            <img className="marca__imagen lazyload" data-src={props.marca} alt="marcas" />
        </div>
    )
}

export default function SeccionMarcas() {
    const marca = [marca1, marca2, marca3, marca4, marca5, marca6, marca7, marca8, marca9, marca10, marca11, marca12, marca13]
    return (
        <div className="seccion-marcas" >
            <Container className="seccion-marcas__container" >
                {
                    marca.map((item, i) => {
                        return <Marca key={i} marca={item} />
                    })
                }
            </Container>
        </div>
    )
}

