import React from 'react'

export default function BeforeOfTitle({ text }) {
    const style = {
        margin: '20px 0px',
        fontSize: '1em',
        lineHeight: '1em',
        letterSpacing: '0.1em',
        fontFamily: 'Lato',
        fontWeight: '700',
        textTransform: 'uppercase',
    }
    return (
        <div style={style}>
            {text}
        </div>
    )
}
