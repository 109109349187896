import './SeccionPartners.css'
import React from 'react'
import { Row, Col, Container } from 'reactstrap'
import partner1 from './media/Partners/Benycar Spa PNG 128px-min.png'
import partner2 from './media/Partners/MService PNG 128px-min.png'
import partner3 from './media/Partners/SVR Ingenieria PNG 128px-min.png'
import Separador from './Separador'
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

const Partners = (props) => {
    return (
        <Col className="partners">
            <img className="imagen-partners lazyload" data-src={props.partner} alt="partners de Bentz Ingeniería" />
        </Col>
    )
}

export default function SeccionPartners() {
    const partner = [partner1, partner2, partner3]
    return (
        <Container className="seccion-partners" >
            <Separador subtitulo="Nos apoyan" titulo="Nuestros partners" opacity="0.3" />
            <div className="container-seccion-partners" >
                <Row xs="2" sm="3" className="seccion-partners__row" >
                    {
                        partner.map((item, i) => {
                            return <Partners key={i} partner={item} />
                        })
                    }
                </Row>
            </div>
        </Container>
    )
}
