import './SeccionInfoEmpresa.css';
import React from 'react'
import { Row, Col, Container } from 'reactstrap';
import { MDBAnimation } from "mdbreact";
import Separador from './Separador';
import { HeartIcon, StarIcon } from '@primer/octicons-react';

export default function SeccionInfoEmpresa() {
    const resumen = "Prestamos servicios de ingeniería eléctrica y de automatización industrial, que actualmente se dedica al diseño, ejecución e integración de proyectos domiciliarios e industriales específicamente proyectos de ingeniería eléctrica y automatización de procesos industriales a menor escala.";
    const mision = "Brindar un servicio de excelencia a nuestros clientes, entregando soluciones tecnológicas en las áreas de la ingeniería eléctrica, instrumentación y automatización industrial que se adecuen a sus necesidades fundamentales, logrando relaciones de confianza que sean sustentables en el tiempo.";
    const vision = "Ser referentes en el desarrollo de soluciones tecnológicas en el mercado industrial y domiciliario a nivel nacional, mejorando la productividad y eficiencia de los procesos productivos de nuestros clientes y manteniendo siempre altos estándares de calidad en cada uno de los servicios prestados.";
    const sizeIcon = 30;
    const sizeBigIcon = 120;
    return (
        <div id="quienes_somos">
        <Container className="container-info-empresa">
            <Separador subtitulo="Conócenos" titulo="¿Quiénes somos?" opacity="0.3" />
            <Row xs="1" sm="1" md="3">
                <Col>
                    <MDBAnimation reveal type="fadeInDown">
                        <h3 className="titulo-empresa">
                            Bentz Ingeniería
                        </h3>
                    </MDBAnimation>
                    <br />
                    <p>
                        {resumen}
                    </p>
                </Col>
                <Col className="col-mision" >
                    <div className="icono-mision" >
                        <HeartIcon className="icono" size={sizeIcon} />
                    </div>
                    <br />
                    <h3>
                        Misión
                    </h3>
                    <br />
                    <p>
                        {mision}
                    </p>
                    <HeartIcon className="icono-grande-mision" size={sizeBigIcon} />
                </Col>
                <Col className="col-vision" >
                    <div className="icono-vision" >
                        <StarIcon className="icono" size={sizeIcon} />
                    </div>
                    <br />
                    <h3>
                        Visión
                    </h3>
                    <br />
                    <p>
                        {vision}
                    </p>
                    <StarIcon className="icono-grande-vision" size={sizeBigIcon} />
                </Col>
            </Row>
            <br />
            <br />
        </Container>
        </div>
    )
}
