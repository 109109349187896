import React, { useEffect } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
// import 'normalize.css'
import './App.css';
import $ from 'jquery';

import loadable from '@loadable/component'

import BarraDeNavegacion from '../components/BarraDeNavegacion';
import SeccionInicio from '../components/SeccionInicio';

// import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"

import SeccionInfoEmpresa from '../components/SeccionInfoEmpresa';
import SeccionPropuestaDeValor from '../components/SeccionPropuestaDeValor';
import SeccionServicios from '../components/SeccionServicios';
import SeccionComoTrabajamos from '../components/SeccionComoTrabajamos';
import SeccionClientes from '../components/SeccionClientes';
import SeccionPartners from '../components/SeccionPartners';
import SeccionMarcas from '../components/SeccionMarcas';
// import SeccionCatalogo from '../components/SeccionCatalogo';
import SeccionPortafolio from '../components/SeccionPortafolio';
import SeccionContacto from '../components/SeccionContacto';
// import { string } from 'prop-types';

// import Footer from '../components/Footer';

// const SeccionInfoEmpresa = loadable(() => import('../components/SeccionInfoEmpresa'))
// const SeccionPropuestaDeValor = loadable(() => import('../components/SeccionPropuestaDeValor'))
// const SeccionServicios = loadable(() => import('../components/SeccionServicios'))
// const SeccionComoTrabajamos = loadable(() => import('../components/SeccionComoTrabajamos'))
// const SeccionClientes = loadable(() => import('../components/SeccionClientes'))
// const SeccionPartners = loadable(() => import('../components/SeccionPartners'))
// const SeccionMarcas = loadable(() => import('../components/SeccionMarcas'))
const SeccionCatalogo = loadable(() => import('../components/SeccionCatalogo'))
// const SeccionPortafolio = loadable(() => import('../components/SeccionPortafolio'))
// const SeccionContacto = loadable(() => import('../components/SeccionContacto'))

const IndexPage = () => {
  useEffect(() => {
    // const navbarLink = document.querySelectorAll('a');
    // navbarLink.forEach(item => {
    //   item.onclick = function (event) {
    //     console.log(item.getAttribute('href'));
    //     const href = item.getAttribute('href');
    //     if (typeof (item.getAttribute('href')) === 'string') {
    //       if (href.charAt(0) === '#') {
    //         event.preventDefault();
    //         console.log(item);
    //         const target = document.querySelector(href);
    //         console.log(target);
    //         // target.scrollIntoView();
    //         // target.scrollIntoView({block: "end", behavior: "smooth"});
    //         const body = document.body;
    //         body.animate({
    //           // scrollTop: '100px'
    //           scrollTop: target.offsetTop
    //         }, 1000);
    //       }
    //     }
    //   };

    // })

    $(document).ready(function () {
      $("a").on('click', function (event) {
        if (this.hash !== "") {
          event.preventDefault();
          var hash = this.hash;
          $('html, body').animate({
            scrollTop: $(hash).offset().top - $(".navbar-brand").height()
          }, 1000);
        }
      });
    });
  }, [])
  return (
    <div>
      <BarraDeNavegacion />
      <Layout >
        <SEO title="Bentz Ingeniería Spa." lang="es" />
        <SeccionInicio />
        <SeccionInfoEmpresa />
        <SeccionPropuestaDeValor />
        <SeccionServicios />
        <SeccionPortafolio />
        <SeccionComoTrabajamos />
        <SeccionCatalogo />
        <SeccionClientes />
        <SeccionPartners />
        <SeccionMarcas />
        <SeccionContacto />
      </Layout>
    </div>
  )
}

export default IndexPage
