import './SeccionPropuestaDeValor.css';
import './imagenes.css'
import React from 'react'
import { HeartFillIcon, CheckCircleIcon, LightBulbIcon, ThumbsupIcon, PeopleIcon, ClockIcon, LawIcon } from '@primer/octicons-react'
import { Container, Media } from 'reactstrap';
import { MDBAnimation } from 'mdbreact';
import Separador from './Separador';
import ImagenConDiv from './ImagenConDiv';
import fondo from './media/fondo_propuesta_de_valor.jpg'


const PropuestaDeValor = (props) => {
    return (
        <MDBAnimation reveal type="fadeInDown">
            <Media>
                <Media left>
                    <div >
                        {props.icono}
                    </div>
                </Media>
                <Media body>
                    <Media heading>
                        {props.titulo}
                    </Media>
                    <p className="propuesta-de-valor__p" >
                        {props.texto}
                    </p>
                </Media>
            </Media>
            <br />
        </MDBAnimation>
    )
}

export default function SeccionPropuestaDeValor() {
    const icono = [<HeartFillIcon size={40} />, <CheckCircleIcon size={40} />, <LawIcon size={40} />, <ClockIcon size={40} />, <LightBulbIcon size={40} />, <ThumbsupIcon size={40} />, <PeopleIcon size={40} />];
    const texto_propuesta = [
        { titulo: "COMPROMISO", texto: "Entregamos las mejores soluciones tecnológicas acorde a sus necesidades y requerimientos." },
        { titulo: "CALIDAD", texto: "Perfeccionamos nuestros procesos, a través de una mejora continua, entregando productos y servicios de excelencia para el usuario." },
        { titulo: "COMPETITIVIDAD PRECIO/CALIDAD", texto: "Actualizamos permanentemente nuestros precios para ofrecer la mejor opción en precio y calidad." },
        { titulo: "PUNTUALIDAD", texto: "Cumplimos con los compromisos y obligaciones en el tiempo acordado" },
        { titulo: "INNOVACIÓN", texto: "Perfeccionamos nuestros procesos a través de mejoras continuas, buscando alcanzar la excelencia." },
        { titulo: "SUPERAMOS EXPECTATIVAS", texto: "Nuestro foco es sorprender a nuestros clientes a través de las soluciones y atención al cliente." },
        { titulo: "RESPONSABILIDAD SOCIAL", texto: "Impulsamos el desarrollo sustentable de la sociedad a través de actividades comunitarias." }
    ]
    return (
        <div id="diferenciacion" >
            <ImagenConDiv src={fondo} alt={"fondo"} clase="aclarar">
                <Container style={{ textAlign: "left", color: "black" }} >
                    <Separador subtitulo="¿Qué nos diferencia?" titulo="Propuesta de valor" display="none" />
                    {
                        texto_propuesta.map((item, i) => {
                            return <PropuestaDeValor key={i} titulo={item.titulo} texto={item.texto} icono={icono[i]} />
                        })
                    }
                </Container>
            </ImagenConDiv>
        </div>
    )
}
