import './SeccionClientes.css'
import React from 'react'
import { Container } from 'reactstrap'
import Separador from './Separador'
import cliente1 from './media/clientes/AquaChile PNG 128px-min.png'
import cliente5 from './media/clientes/Australis Seafoods PNG 128px-min.png'
import cliente2 from './media/clientes/CCU PNG 128px-min.png'
import cliente3 from './media/clientes/Chile Compra PNG 128px-min.png'
import cliente6 from './media/clientes/Comercializadora Latin Alliance PNG 128px-min.png'
import cliente4 from './media/clientes/Ivomar PNG 128px-min.png'
import cliente7 from './media/clientes/UOCT PNG 128px-min.png'

import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

const Clientes = (props) => (
    <div className="clientes__col">
        <img className="clientes__imagen lazyload" data-src={props.cliente} alt="clientes" />
    </div>
)

export default function SeccionClientes() {
    const cliente = [cliente1, cliente2, cliente3, cliente4, cliente5, cliente6, cliente7]
    return (
        <div className="seccion-clientes" >
            <Container className="seccion-clientes__container" >
                <Separador style={{ color: "white" }} subtitulo="Nos respaldan" titulo="Nuestros Clientes" />
                <div className="seccion-clientes__row" xs="2" sm="4" >
                    {
                        cliente.map((item, i) => {
                            return <Clientes key={i} cliente={item} />
                        })
                    }
                </div>
            </Container>
        </div>
    )
}
