import React from 'react';
import { Navbar, NavbarBrand, NavItem, NavLink, Nav, NavbarToggler, Collapse } from 'reactstrap';
import './BarraDeNavegacion.css'
import logo from "./media/logo_empresa.jpg";
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

const Texto = (props) => {
    return (
        <Nav>
            <NavItem >
                <NavLink onClick={event => props.click_handler(event)} className="texto" href={props.link} >
                    {props.texto}
                </NavLink>
            </NavItem>
        </Nav>
    )
}

const LogoNav = () => {
    const style = {
        brand: {
            width: "auto",
            height: "70px"
        },
        containerBrand: {
            paddingTop: "10px",
            paddingBottom: "10px"
        }
    };
    return (
        <div className="mr-auto" >
            <NavbarBrand style={style.containerBrand}>
                <img data-sizes="auto" className="lazyload" style={style.brand} data-src={logo} alt="Logo" />
            </NavbarBrand>
        </div>
    )
}

export default function BarraDeNavegacion() {

    // React.useEffect(() => {
    //     const scrollUp = "scroll-up";
    //     const scrollDown = "scroll-down";
    //     let lastScroll = 0;
    //     function scrollEvent() {
    //         const body = document.getElementById("barra_navegacion");
    //         const currentScroll = window.pageYOffset;
    //         if (currentScroll > 800) {
    //             if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
    //                 body.classList.remove(scrollUp);
    //                 body.classList.add(scrollDown);
    //             } else if (currentScroll < lastScroll && body.classList.contains(scrollDown)) {
    //                 body.classList.remove(scrollDown);
    //                 body.classList.add(scrollUp);
    //             }
    //             lastScroll = currentScroll;
    //         }
    //     }
    //     window.addEventListener("scroll", scrollEvent, { passive:true });
    //     return () => {
    //         window.removeEventListener("scroll", scrollEvent, { passive:true });
    //     }
    // })

    const texto_barra_navegacion = [
        { nombre: "INICIO", link: "#inicio" },
        { nombre: "¿QUIÉNES SOMOS?", link: "#quienes_somos" },
        { nombre: "DIFERENCIACIÓN", link: "#diferenciacion" },
        { nombre: "SERVICIOS", link: "#servicios" },
        { nombre: "PORTAFOLIO", link: "#portafolio" },
        { nombre: "¿COMO TRABAJAMOS?", link: "#como_trabajamos" },
        { nombre: "CATÁLOGO", link: "#catalogo" },
        // { nombre: "CLIENTES", link: "#clientes" },
        { nombre: "CONTACTO", link: "#contacto" }
    ];

    React.useEffect(()=>{

    },[]);



    const [isOpen, setIsOpen] = React.useState(false);
    const toggle = () => setIsOpen(!isOpen);

    const click_handler = event => {
        if (isOpen) {
            setIsOpen(false);
        }

        // var href = event.target.href;
        // var isInclude = href.includes("#contacto");
        // if(isInclude){
        //     document.getElementById('Nombre').focus();
        // }
    }

    return (
        <header >
            <Navbar id="barra_navegacion" light className="barra-navegacion" expand="lg" >
                <LogoNav />
                <NavbarToggler onClick={toggle}/>
                <Collapse isOpen={isOpen} navbar>
                    <Nav className="mr-auto">
                    </Nav>
                    {
                        texto_barra_navegacion.map((item, i) => {
                            return <Texto click_handler={click_handler} key={i} texto={item.nombre} link={item.link} />
                        })
                    }
                </Collapse>
            </Navbar>
        </header>
    );
}
