import './SeccionContacto.css';
import React, { useState, Component } from 'react';
import { Row, Col, Button, Form, FormGroup, Label, Input, Container, Tooltip } from 'reactstrap';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import Separador from './Separador';
import { Call, LocationOn, MailOutline } from '@material-ui/icons';
import { Spinner } from 'reactstrap';

const RedesSociales = (props) => {
    return (
        <Col xs="auto" className="container-redes-sociales" >
            <a href={props.link} rel="noopener noreferrer" target="_blank" >
                {props.icono}
            </a>
        </Col>
    )
}

const Contacto = () => {
    const [tooltipUbicacion, setTooltipUbicacion] = useState(false)
    const [tooltipTelefono, setTooltipTelefono] = useState(false)
    const [tooltipCorreo, setTooltipCorreo] = useState(false)

    const toggleUbicacion = () => setTooltipUbicacion(!tooltipUbicacion);
    const toggleTelefono = () => setTooltipTelefono(!tooltipTelefono);
    const toggleCorreo = () => setTooltipCorreo(!tooltipCorreo);

    const mensajeTooltip = ["Ubícanos", "Llámanos", "Contáctanos"]
    const tooltipId = ["tooltipUbicacion", "tooltipTelefono", "tooltipCorreo"]

    const ubicacion = "https://g.page/Bentz_Ingenieria?share"
    const telefono = "+ 56 9 9558 9844"
    const correo = "contacto@bentz.cl"

    return (
        <div className="container-contacto" >
            <p>
                <span>Bentz Ingeniería</span> es una empresa de servicio dedicada al desarrollo de proyectos eléctricos, automatización e instrumentación industrial. La principal iniciativa está enfocada en la industria acuícola, lechera, alimentaria y en viviendas, realizando trabajos en la Región de los Lagos.
            </p>
            <div >
                <div >
                    <a id={tooltipId[0]} href={ubicacion} rel="noopener noreferrer" target="_blank" >
                        <LocationOn className="icono-contacto" />
                        Puerto Sara #2120, Brisamar I, Puerto Montt, Chile</a>
                    <Tooltip placement="right" isOpen={tooltipUbicacion} target={tooltipId[0]} toggle={toggleUbicacion}>
                        {mensajeTooltip[0]}
                    </Tooltip>
                </div>
                <div >
                    <a id={tooltipId[1]} href={"tel:" + telefono} >
                        <Call className="icono-contacto" />
                        {telefono}</a>
                    <Tooltip placement="right" isOpen={tooltipTelefono} target={tooltipId[1]} toggle={toggleTelefono}>
                        {mensajeTooltip[1]}
                    </Tooltip>
                </div>
                <div >
                    <a id={tooltipId[2]} href={"mailto:" + correo} >
                        <MailOutline className="icono-contacto" />
                        {correo}</a>
                    <Tooltip placement="right" isOpen={tooltipCorreo} target={tooltipId[2]} toggle={toggleCorreo}>
                        {mensajeTooltip[2]}
                    </Tooltip>
                </div>
            </div>
        </div>
    )
}


const Respuesta = (props) => {
    if (props.respuesta !== '') {
        return (<p style={{ color: 'red' , fontFamily: "Lato",
        fontWeight: "700"}} > {props.respuesta} </p>)
    } else {
        return null;
    }
}

const Cargando = (props) => {
    if (props.show === true) {
        return (
            <div style={{display:"block"}} >
                <Spinner color="danger" />
            </div>
        )
    }
    else {
        return null;
    }
}

class Formulario extends Component {

    constructor(props) {
        super(props);
        this.state = {
            nombre: "",
            email: "",
            mensaje: "",
            respuesta: "",
            data: null,
            show: false,
        }
    }

    URL = 'https://bentz.cl';
    // URL = 'http://localhost:3000';
    // https://bentz.cl/send-email

    mensajeEsperado = 'correo enviado';

    respuestaSatisfactorio = 'Su mensaje a sido enviado de forma exitosa'
    respuestaErronea = 'A ocurrido un error mientras se intentaba enviar su mensaje';

    enviarDatos = e => {
        e.preventDefault();
        this.metodoPost(); //enviar formulario
        this.setState({ show: true })
    }

    escribiendo = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    resetearRespuesta = () => {
        this.setState({ respuesta: '' });
    }

    detenerSpinner = () => {
        this.setState({ show: false });
    }

    metodoPost = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                nombre: this.state.nombre,
                email: this.state.email,
                mensaje: this.state.mensaje,
            })
        };
        fetch(this.URL + '/send-email', requestOptions)
            .then(async response => {
                this.setState({
                    nombre: '',
                    email: '',
                    mensaje: '',
                })
                const data = await response.json();

                this.detenerSpinner();

                if (data === this.mensajeEsperado) {
                    // console.log("concuerdan");
                    this.setState({ respuesta: this.respuestaSatisfactorio })
                    setTimeout(this.resetearRespuesta, 5000);
                } else {
                    this.setState({ respuesta: this.respuestaErronea })
                }
                // return data;
            });
    }

    render() {
        return (
            <Form onSubmit={this.enviarDatos} >
                {/* // <Form action="/send-email" method="POST"  > */}
                <FormGroup>
                    <Label for="Nombre">Nombre</Label>
                    <Input onChange={this.escribiendo} value={this.state.nombre} type="name" name="nombre" id="Nombre" placeholder="Escribe tu nombre aquí" required />
                </FormGroup>
                <FormGroup >
                    <Label for="Email">Email</Label>
                    <Input onChange={this.escribiendo} value={this.state.email} type="email" name="email" id="Email" placeholder="Escribe tu email aquí" required />
                </FormGroup>
                <FormGroup>
                    <Label for="Mensaje">Mensaje</Label>
                    <Input style={{ resize: "none", height: "100px" }} onChange={this.escribiendo} value={this.state.mensaje} type="textarea" name="mensaje" id="Mensaje" placeholder="Escríbenos un mensaje" required />
                </FormGroup>
                <Cargando show={this.state.show} />
                <Respuesta respuesta={this.state.respuesta} />
                <Button color="#1f56b5" style={{color:"#fff" , backgroundColor:"#1f56b5"}}  >Enviar</Button>
                <p style={{ marginTop: "10px" }} >
                    <span style={{ color: "#1f56b5" }} >Nota:</span> Sus datos se mantienen estrictamente confidenciales según nuestra Política de Privacidad.
                </p>
            </Form >
        )
    }
}

export default function SeccionContacto() {
    const size = 50
    const redes_sociales = [
        { link: "https://www.facebook.com/BentzIngenieria/", icono: <FacebookIcon style={{ fontSize: size, color: "#3b5998" }} /> },
        { link: "https://www.instagram.com/bentz_chile/", icono: <InstagramIcon style={{ fontSize: size, color: "#E1306C" }} /> },
        { link: "https://www.linkedin.com/company/40656844", icono: <LinkedInIcon style={{ fontSize: size, color: "#2867B2" }} /> }
    ]
    return (
        <div id="contacto" className="container-seccion-contacto" >
            <Container >
                <Separador subtitulo="Escríbenos" titulo="Sigamos en contacto" opacity="0.3" />
                <Row xs="1" sm="1" md="2" >
                    <Col style={{ padding: "0px 5%" }} >
                        <Row>
                            {
                                redes_sociales.map((item, i) => {
                                    return <RedesSociales key={i} link={item.link} icono={item.icono} />
                                })
                            }
                        </Row>
                        <br />
                        <Contacto />
                    </Col>
                    <Col style={{ padding: "0px 5%" }} >
                        <br />
                        <Formulario />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
