import './SeccionPortafolio.css'
import React from 'react'
import { Container } from 'reactstrap'
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import { MDBAnimation } from 'mdbreact'

import servicio1 from './media/Portafolio/1-min.png'
import servicio2 from './media/Portafolio/2-min.jpg'
import servicio3 from './media/Portafolio/3-min.jpg'
import servicio4 from './media/Portafolio/4-min.png'
import servicio5 from './media/Portafolio/5-min.png'
import servicio6 from './media/Portafolio/6-min.png'
import servicio7 from './media/Portafolio/7-min.png'
import servicio8 from './media/Portafolio/8-min.png'
import servicio9 from './media/Portafolio/9-min.png'
import Separador from './Separador'

const Servicios = (props) => {
    return (
        <div className="col-container-servicios-portafolio">
            <MDBAnimation reveal type="fadeInUpBig" className="animacion-servicios" >
                <div className="div-servicios-portafolio" >
                    <img className="imagen-servicios-portafolio lazyload" data-src={props.imagen} alt="servicios de bentz ingeniería" />
                    <br />
                    <h5>{props.titulo}</h5>
                    <p>{props.descripcion}</p>
                </div>
                <br />
            </MDBAnimation>
        </div>
    )
}

export default function SeccionPortafolio() {
    const servicio = [
        {
            titulo: "Migración de tablero eléctrico OXXEAN",
            descripcion: "Canalización EMT + Traslado de tablero eléctrico de alumbrado en dependencias de la empresa Oxxean, con el objetivo de expandir la sala eléctrica",
            imagen: servicio1,
            id: 1
        },
        {
            titulo: "Habilitación eléctrica contenedores AquaChile",
            descripcion: "Instalación de alimentador 4x4mm2 a 80 metros de distancia, para habilitación eléctrica de contenedores en las dependencias de AquaChile Calbuco, contemplando además una inspección eléctrica al interior de los contenedores",
            imagen: servicio2,
            id: 2
        },
        {
            titulo: "Mantención preventiva mediante termografía a tableros eléctricos Abick S.A.",
            descripcion: "Mantención preventiva a 11 Tableros eléctricos de alumbrado, control y fuerza en pontones de la empresa Abick S.A., con técnica de termografía, utilizando cámara Flir E4",
            imagen: servicio3,
            id: 3
        },
        {
            titulo: "Canalización para iluminación industrial pontones AquaChile",
            descripcion: "Canalización para 4 torres de iluminación ubicadas en pontones de AquaChile Calbuco, pasando por ductos tipo plansa. llegando a tablero eléctrico ubicado a 110 metros de distancia",
            imagen: servicio4,
            id: 4
        },
        {
            titulo: "Mantención correctiva planta enfardadora JOVISA, Empresa SOREPA",
            descripcion: "Mantención de tablero eléctrico, actuadores y sensores en Planta enfardadora JOVISA ubicada en la empresa SOREPA Puerto Montt",
            imagen: servicio5,
            id: 5
        },
        {
            titulo: "Instalación alimentador 5x6mm2 140 metros Aguas Claras",
            descripcion: "Instalación de alimentador de 5x6mm2 a una distancia de 140 metros para habilitación de tablero eléctrico bombas RILES",
            imagen: servicio6,
            id: 6
        },
        {
            titulo: "Cierre perimetral Transportes CCU Puerto Montt",
            descripcion: "Instalación de cierre perimetral para bodega de almacenamiento CCU en 3 puntos, 6 pares de 200 mts",
            imagen: servicio7,
            id: 7
        },
        {
            titulo: "Mantención generador eléctrico 250 kVA CCU Puerto Montt",
            descripcion: "Mantención anual generador eléctrico de 250 KVA ubicado en dependencias de CCU Puerto Montt",
            imagen: servicio8,
            id: 8
        },
        {
            titulo: "Instalación de luminarias Brisa LED 120W CCU Puerto Montt",
            descripcion: "Instalación de luminarias brisa LED 120W en estacionamientos CCU Puerto Montt",
            imagen: servicio9,
            id: 9
        }
    ]
    return (
        <div id="portafolio" >
            <Container>
                <Separador subtitulo="Proyectos ejecutados" titulo="Portafolio" opacity="0.3" />
                <div className="row-seccion-portafolio" >
                    {
                        servicio.map((item, i) => {
                            return <Servicios key={i} titulo={item.titulo} descripcion={item.descripcion} imagen={item.imagen} />
                        })
                    }
                </div>
            </Container>
        </div>
    )
}
